import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
// import { composeValidations, email, required } from '@shoreag/validations';
import Box from '@shoreag/base/Box';
import Modal from '@shoreag/base/Modal';
import Input from '@shoreag/base/Input';
import Button from '@shoreag/base/Button';
import { FORM_ERROR } from 'final-form';
import Dropdown from '@shoreag/base/Dropdown';
import Card from '@shoreag/base/Card';
import arrayMutators from 'final-form-arrays';
import { Mutation } from 'react-apollo';
// import AddButton from '../AddButton';
import createClientGroup from '../../graphql/mutations/create-client-group.gql';
import useSnackbar from '../../utilities/use-snackbar';
import AllPartnersDropdown from '../AllPartnersDropdown';
import AllClientUserDropdown from '../AllClientUserDropdown';
import PillsGroup from '../PillsGroup';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import RoleFormSection from '../RoleFormSection';
import FormError from '../FormError';
import CheckUserExists from '../CheckUserExists';

const allRoleDropdown = [
  {
    id: 'shore:dex:role::41ebd714-a308-440d-b7bf-590d9d24e2a4',
    roleName: 'Text 1',
  },
  {
    id: 'shore:dex:role::34894d56-f228-4eb1-b67d-3188c5718636',
    roleName: 'Admin',
  },
  {
    id: 'shore:dex:role::b8edb391-267b-45c5-b99d-6b954c2f5b1d',
    roleName: 'Trust 1 Update',
  },
];
const ClientGroupCreatePage = ({ clientId, navigate }) => {
  const { user: authUser } = useContext(AuthContext);
  if (!authUser.isAdmin) return <NotFoundPage />;

  const [showModal, setShowModal] = useState(false);
  // const [showInviteBox, setShowInviteBox] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const [setSnack] = useSnackbar();
  // const newRoleButton = (
  //   <Box
  //     onClick={toggleModal}
  //     sx={{
  //       color: 'accent',
  //       cursor: 'pointer',
  //     }}
  //   >
  //     Create new role
  //   </Box>
  // );
  return (
    <Route
      header={{
        icon: 'users',
        title: 'Create Group',
        type: 'User',
      }}
      isPrivate
    >
      <Mutation mutation={createClientGroup}>
        {(createClientGroup) => (
          <FinalForm
            initialValues={{
              groupName: '',
              partners: [],
              roleId: '',
              users: [],
            }}
            mutators={arrayMutators}
            onSubmit={async (values) => {
              const payload = {
                variables: {
                  clientId,
                  groupName: values?.groupName ? values.groupName : '',
                  partners: (values?.partners || []).map(
                    (partner) => partner.id
                  ),
                  roleId: values?.roleId ? values.roleId : '',
                  users: (values?.users || []).map((user) => user.email),
                },
              };

              try {
                const {
                  data: {
                    createClientGroup: { groupName },
                  },
                } = await createClientGroup(payload);

                if (groupName) {
                  setSnack(
                    <Box sx={{ textAlign: 'center' }}>
                      UserGroup:
                      <b> {groupName}</b>
                      is created&nbsp;
                      <Box as="span" sx={{ color: 'success' }}>
                        successfully.
                      </Box>
                    </Box>
                  );
                }

                navigate(`/client/${clientId}`);
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={(formContext) => {
              const users = formContext?.values?.users || [];
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    display={{ lg: 'flex' }}
                    justifyContent="center"
                    sx={{ gap: '40px', px: 5 }}
                  >
                    <Card width="100%">
                      <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                        Details
                      </Box>
                      <Field
                        component={Input}
                        label="Enter Group Name"
                        name="groupName"
                        wrapperSx={{ mb: 5 }}
                      />
                      <Field
                        component={Dropdown}
                        label="Role"
                        name="roleId"
                        options={allRoleDropdown.map(({ id, roleName }) => ({
                          label: roleName || '-',
                          value: id,
                        }))}
                      />
                      <Box
                        sx={{
                          color: 'accentSecondary',
                          fontSize: 2,
                          mb: 5,
                          mt: 1,
                        }}
                      >
                        This role will be assigned to all the users belonging to
                        this group
                      </Box>
                      <AllPartnersDropdown
                        isMulti
                        isRequired={false}
                        name="partners"
                        showLabel
                        wrapperSx={{
                          mt: 3,
                        }}
                      />

                      <PillsGroup
                        data={get(formContext.values, `partners`, []).map(
                          (partner) => ({
                            label: partner.partnerName,
                          })
                        )}
                        onRemove={(parameter) =>
                          formContext.form.change(
                            `partners`,
                            get(formContext.values, `partners`, []).filter(
                              (p) => p.partnerName !== parameter.label
                            )
                          )
                        }
                        pillSx={{
                          '& a': { textDecoration: 'none' },
                          '& div': { bg: 'accent', color: 'white' },
                          '&:hover': { bg: 'accent' },
                          bg: 'accent',
                          borderRadius: '1rem',
                        }}
                        wrapperSx={{
                          pt: 4,
                        }}
                      />
                    </Card>
                    <Card width="100%">
                      <Box as="h2" sx={{ fontSize: 4, mb: 2, mt: 0 }}>
                        Invite user(s)
                      </Box>
                      <Box
                        sx={{
                          color: 'grays.4',
                          fontSize: 2,
                          mt: 1,
                        }}
                      >
                        This user(s) is the admin. The admin will have all the
                        permissions of this client.
                      </Box>
                      <AllClientUserDropdown
                        clientId={clientId}
                        isMulti
                        isRequired={false}
                        name="users"
                        showLabel={false}
                      />
                      {users.length > 0 && (
                        <CheckUserExists
                          clientId={clientId}
                          formContext={formContext}
                          users={users}
                        />
                      )}
                      {/* {showInviteBox && (
                        <Box
                          mt={4}
                          sx={{
                            alignItems: 'flex-end',
                            display: 'flex',
                            gap: '10px',
                          }}
                        >
                          <Field
                            component={Input}
                            label="Invite New User"
                            name="newUsers"
                            validate={composeValidations(required, email)}
                          />
                          <Button>Invite</Button>
                        </Box>
                      )}
                      <AddButton
                        label="Add user"
                        labelSx={{
                          color: 'accent',
                        }}
                        onClick={() => setShowInviteBox(!showInviteBox)}
                        sx={{
                          '&:hover': { bg: 'accentLight' },
                          alignItems: 'center',
                          bg: 'accent',
                          display: 'flex',
                          fontSize: 4,
                          height: '16px',
                          justifyContent: 'center',
                          width: '16px',
                        }}
                        wrapperSx={{ mt: 3 }}
                      /> */}
                    </Card>
                  </Box>

                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'center',
                      mt: 6,
                      width: 'auto',
                    }}
                  >
                    <Button
                      sx={{
                        '&:hover': { bg: 'transparent' },
                        bg: 'transparent',
                        borderColor: 'accent',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        color: 'accent',
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      submitting={formContext.submitting}
                      type="submit"
                      width="submitButton"
                    >
                      Create Group
                    </Button>
                  </Box>

                  <Modal
                    bodySx={{
                      px: 0,
                    }}
                    closeText="No"
                    footer={<></>}
                    isOpen={showModal}
                    onClose={toggleModal}
                    onSubmit={formContext.handleSubmit}
                    style={{
                      content: {
                        maxWidth: '800px',
                        overflowY: 'auto',
                      },
                    }}
                    submitText="Yes"
                    submitting={formContext.submitting}
                  >
                    <RoleFormSection navigate={navigate} />
                  </Modal>
                </form>
              );
            }}
          />
        )}
      </Mutation>
    </Route>
  );
};

ClientGroupCreatePage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

ClientGroupCreatePage.defaultProps = {
  clientId: null,
};

export default ClientGroupCreatePage;
