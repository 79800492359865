import Dropdown from '@shoreag/base/Dropdown';
import React from 'react';
import Icon from '../Icon';

const iconSx = {
  '&:hover': { fill: 'accentDark' },
  fill: 'accent',
  transition: 'fill 0.2s',
  width: '1rem',
};

const check = <Icon svg="checkbox" sx={iconSx} />;

const uncheck = <Icon svg="uncheck" sx={iconSx} />;

const MultiSelectDropdown = ({ ...rest }) => (
  <Dropdown checkIcon={check} isMulti uncheckIcon={uncheck} {...rest} />
);

export default MultiSelectDropdown;
