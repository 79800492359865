import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import DisplayPartnerData from '../../DisplayPartnerData';

const ClientDisplayPartnerData = ({ partners, partnersLength }) => {
  return (
    <>
      <Box as="h2" color="grays.4" fontSize={3} fontWeight={400} mb={4}>
        Partners:
      </Box>
      <Box
        sx={{
          breakInside: 'avoid',
          columnCount: partnersLength > 4 ? 3 : 0,
        }}
      >
        {partnersLength > 0 ? (
          partners.map((partner, i) => {
            return (
              <Box key={i} fontSize={2} mb={2}>
                <DisplayPartnerData partnerId={partner.id} />
              </Box>
            );
          })
        ) : (
          <Box>-NA-</Box>
        )}
      </Box>
    </>
  );
};

ClientDisplayPartnerData.defaultProps = {
  partners: [],
};

ClientDisplayPartnerData.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  partnersLength: PropTypes.number.isRequired,
};

export default ClientDisplayPartnerData;
