import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import { Field } from 'react-final-form';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';

const ClientFeatureItem = ({ feature, index }) => (
  <Box
    key={feature.id}
    alignItems="center"
    display="flex"
    justifyContent="space-between"
    sx={{
      borderBottom: '1px solid',
      borderColor: 'gray2',
      pb: 4,
      pt: 5,
    }}
  >
    <Box fontSize={3} sx={{ color: 'grays.4' }}>
      {feature.featuresName}
    </Box>
    <Field
      component={ToggleSwitch}
      name={`features[${index}]`}
      type="checkbox"
      wrapperSx={{
        flex: 'none',
        m: 0,
      }}
    />
  </Box>
);

ClientFeatureItem.defaultProps = {
  index: 0,
};

ClientFeatureItem.propTypes = {
  feature: PropTypes.shape({
    featuresName: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
};

export default ClientFeatureItem;
