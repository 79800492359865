import React from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import Table from '@shoreag/base/Table';
import { Query, useMutation } from 'react-apollo';
import { Form } from 'react-final-form';
import { TooltipBox } from '@shoreag/base/Tooltip';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import deleteClientUserMutation from '../../graphql/mutations/delete-client-user.gql';
import deleteClientGroupMutation from '../../graphql/mutations/delete-client-group.gql';
import dashboardConstants from '../../utilities/dashboard-constants';
import useSnackbar from '../../utilities/use-snackbar';
import Route from '../Route';
import ToolbarButton from '../ToolbarButton';
import UserSearchFilterForm from '../UserSearchFilterForm';
import DisplayUserSearchName from '../DisplayUserSearchName';
import DisplayRolesData from '../DisplayRolesData';
import DisplayTradingPartners from '../DisplayTradingPartners';
import DotsIcon from '../DotsIcon';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import DashboardPaginator from '../DashboardPaginator';
import setDashboardURL from '../../utilities/set-dashboard-url';
import allClientUserQuery from '../../graphql/queries/all-client-user.gql';

const ClientDirectoryDashboardPage = ({ clientId, location, navigate }) => {
  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'client_users',
  });

  const { values } = filters;

  const [deleteClientUser] = useMutation(deleteClientUserMutation);
  const [deleteClientGroup] = useMutation(deleteClientGroupMutation);

  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });

  return (
    <Route
      header={{
        rightContainer: (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <ToolbarButton
              active
              icon="plus"
              label="User"
              link={`/client/${clientId}/create-user`}
            />
            <ToolbarButton
              icon="plus"
              label="Group"
              link={`/client/${clientId}/create-group`}
            />
          </Box>
        ),
        title: 'User management',
      }}
      isPrivate
    >
      <UserSearchFilterForm
        clientId={clientId}
        filters={filters}
        page={pageCriteria}
        sort={sortCriteria}
      />
      <Form
        initialValues={{
          name: values?.name || '',
          roleId: values?.roleId || '',
          status: values?.status || '',
        }}
        onSubmit={noop}
        render={(formContext) => {
          const { values, handleSubmit } = formContext;

          return (
            <Box
              as="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Query
                fetchPolicy="network-only"
                notifyOnNetworkStatusChange
                query={allClientUserQuery}
                variables={{
                  searchBody: JSON.stringify({
                    clientId,
                    limit: pageCriteria.pageSize,
                    name: values.name,
                    offset: pageCriteria.offset,
                    optimized: true,
                    roleId: values.roleId,
                    status: values.status,
                  }),
                }}
              >
                {({ data, loading }) => {
                  const allUser = get(data, 'allClientUser.users', []);
                  return (
                    <>
                      <Card m={0} p={5} sx={{ borderRadius: 0 }}>
                        <Table
                          header={[
                            'Name',
                            'No of Users',
                            'Role',
                            'Trading Partners',
                            '',
                          ]}
                          isLoading={loading}
                          rows={allUser?.map((user) => {
                            const dropdownItems = user?.groupName
                              ? [['edit', 'Edit Group']]
                              : [['edit', 'Edit User']];

                            const dropdownActions = user?.groupName
                              ? [`/client/${clientId}/group/${user.id}/edit`]
                              : [`/client/${clientId}/user/${user.id}/edit`];

                            if (user?.groupName) {
                              dropdownItems.push(['close', 'Delete Group']);
                              dropdownActions.push(async () => {
                                try {
                                  const {
                                    data: {
                                      deleteClientGroup: { groupName },
                                    },
                                  } = await deleteClientGroup({
                                    update: (client) => {
                                      client.writeQuery({
                                        data: {
                                          ...allUser,
                                        },
                                        query: allClientUserQuery,
                                      });
                                    },
                                    variables: {
                                      groupName: user.groupName,
                                      id: user.id,
                                    },
                                  });

                                  setSnack(
                                    <Box sx={{ textAlign: 'center' }}>
                                      UserGroup:
                                      <b> {groupName}</b>
                                      is deleted&nbsp;
                                      <Box as="span" sx={{ color: 'error' }}>
                                        successfully.
                                      </Box>
                                    </Box>
                                  );
                                  navigate(`/client/${clientId}`);
                                } catch (e) {
                                  setErrorSnack(
                                    'Deletion failed, try again later'
                                  );
                                }
                              });
                            } else {
                              dropdownItems.push(['close', 'Delete User']);
                              dropdownActions.push(async () => {
                                try {
                                  const {
                                    data: {
                                      deleteClientUser: { email },
                                    },
                                  } = await deleteClientUser({
                                    update: (client) => {
                                      client.writeQuery({
                                        data: {
                                          ...allUser,
                                        },
                                        query: allClientUserQuery,
                                      });
                                    },
                                    variables: {
                                      clientId,
                                      email: user.email,
                                      id: user.id,
                                    },
                                  });

                                  setSnack(
                                    <Box sx={{ textAlign: 'center' }}>
                                      User:
                                      <b> {email}</b>
                                      is deleted&nbsp;
                                      <Box as="span" sx={{ color: 'error' }}>
                                        successfully.
                                      </Box>
                                    </Box>
                                  );
                                  navigate(`/client/${clientId}/all-users`);
                                } catch (e) {
                                  setErrorSnack(
                                    'Deletion failed, try again later'
                                  );
                                }
                              });
                            }

                            let displayName = '';
                            if (user.groupName) {
                              displayName = user.groupName;
                            } else if (user.firstName || user.lastName) {
                              displayName = `${user.firstName} ${user.lastName}`;
                            } else if (user.email) {
                              displayName = user.email;
                            } else {
                              displayName = '-NA-';
                            }

                            return [
                              <DisplayUserSearchName
                                icon={user?.groupName ? 'folder' : 'user'}
                                name={displayName}
                              />,
                              user?.users ? (
                                <Box color="grays.4">{user.users}</Box>
                              ) : (
                                '-'
                              ),
                              user.role ? (
                                <Box color="grays.4" fontWeight={600}>
                                  {user.role}
                                </Box>
                              ) : (
                                <DisplayRolesData roles={user.roles} /> || '-'
                              ),
                              user?.partners?.length ? (
                                <DisplayTradingPartners
                                  partners={user?.partners}
                                />
                              ) : (
                                '-'
                              ),
                              <Box sx={{ float: 'right' }}>
                                <DropdownMenu
                                  button={<DotsIcon />}
                                  itemActions={dropdownActions}
                                  items={dropdownItems.map(
                                    renderDropdownMenuItem
                                  )}
                                />
                              </Box>,
                            ];
                          })}
                        />
                        <TooltipBox id="tooltip" />
                        <TagTooltipBox />
                      </Card>
                      <DashboardPaginator
                        filters={{
                          page: pageCriteria.offset,
                        }}
                        goToNextPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset + pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        goToPreviousPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset - pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        isClientSidePagination
                        pageTotal={allUser.length}
                      />
                    </>
                  );
                }}
              </Query>
            </Box>
          );
        }}
      />
    </Route>
  );
};

ClientDirectoryDashboardPage.propTypes = {
  clientId: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

ClientDirectoryDashboardPage.defaultProps = {
  clientId: null,
};

export default ClientDirectoryDashboardPage;
