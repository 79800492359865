import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React from 'react';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import { Query } from 'react-apollo';
import { Form } from 'react-final-form';
import { get, noop } from 'lodash';
import Spinner from '@shoreag/base/Spinner';
import allClientQuery from '../../graphql/queries/all-client.gql';
import ToolbarButton from '../ToolbarButton';
import DotsIcon from '../DotsIcon';
import Route from '../Route';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import dashboardConstants from '../../utilities/dashboard-constants';
import setDashboardURL from '../../utilities/set-dashboard-url';
import DashboardPaginator from '../DashboardPaginator';
import ClientSearchFilterForm from './ClientSearchFilterForm';
import ClientDisplayFeatureData from './ClientDisplayFeatureData';
import ClientDisplayPartnerData from './ClientDisplayPartnerData';

const ClientDashboardPage = ({ location, navigate }) => {
  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'client',
  });

  const { values } = filters;

  return (
    <Route
      header={{
        rightContainer: (
          <ToolbarButton icon="plus" label="Create New" link="/client/create" />
        ),
        title: 'Client Management',
      }}
      isPrivate
    >
      <ClientSearchFilterForm
        filters={filters}
        page={pageCriteria}
        sort={sortCriteria}
      />
      <Form
        initialValues={{
          name: values.name || '',
          partnerId: values.partnerId || '',
        }}
        onSubmit={noop}
        render={(formContext) => {
          const { values, handleSubmit } = formContext;
          return (
            <Box
              as="form"
              onSubmit={handleSubmit}
              sx={{
                position: 'relative',
              }}
            >
              <Query
                fetchPolicy="network-only"
                notifyOnNetworkStatusChange
                query={allClientQuery}
                variables={{
                  searchBody: JSON.stringify({
                    limit: pageCriteria.pageSize,
                    name: values.name,
                    offset: pageCriteria.offset,
                    partnerId: values.partnerId,
                  }),
                }}
              >
                {({ data, loading }) => {
                  const clientList = get(data, 'allClient.clients', []);
                  const dropdownItems = [['view', 'View']];
                  const dropdownActions = [`/client/`];

                  return loading ? (
                    <Spinner py="md" />
                  ) : (
                    <>
                      {clientList.map((client, i) => {
                        const featuresLength = client?.features?.length;
                        const partnersLength = client?.partners?.length;
                        return (
                          <Card
                            key={i}
                            alignItems="flex-start"
                            display="flex"
                            sx={{
                              borderRadius: 0,
                              height: '140px',
                            }}
                          >
                            <Box
                              sx={{
                                bg: 'grays.1',
                                position: 'absolute',
                                right: '15px',
                                top: '15px',
                                transform: 'rotate(90deg)',
                              }}
                            >
                              <DropdownMenu
                                button={<DotsIcon />}
                                itemActions={dropdownActions}
                                items={dropdownItems.map(
                                  renderDropdownMenuItem
                                )}
                              />
                            </Box>
                            <Box pr={6} width="25%">
                              <Box
                                as="h2"
                                fontSize={3}
                                mb={5}
                                onClick={() => navigate(`/client/${client.id}`)}
                                sx={{
                                  cursor: 'pointer',
                                }}
                              >
                                {client.clientName}
                              </Box>
                              <Box
                                alignItems="flex-start"
                                display="flex"
                                mb={4}
                              >
                                <Box color="grays.4" fontSize={2} width="80px">
                                  Admin:
                                </Box>
                                <Box
                                  fontSize={2}
                                  sx={{ wordWrap: 'break-word' }}
                                >
                                  William.davidson@trustmark.com
                                </Box>
                              </Box>
                              <Box
                                alignItems="center"
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Box alignItems="center" display="flex">
                                  <Box
                                    color="grays.4"
                                    fontSize={2}
                                    width="80px"
                                  >
                                    Users:
                                  </Box>
                                  <Box fontSize={2}>{client.usersCount}</Box>
                                </Box>
                                <Box color="grays.4" fontSize={2}>
                                  (Capacity: {client.capacity})
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                bg: 'grays.1',
                                height: '100%',
                                width: '2px',
                              }}
                            />
                            <Box px={6} width="35%">
                              <ClientDisplayPartnerData
                                partners={client?.partners}
                                partnersLength={partnersLength}
                              />
                            </Box>
                            <Box
                              sx={{
                                bg: 'grays.1',
                                height: '100%',
                                width: '2px',
                              }}
                            />
                            <Box px={6} width="40%">
                              <ClientDisplayFeatureData
                                features={client?.features}
                                featuresLength={featuresLength}
                              />
                            </Box>
                          </Card>
                        );
                      })}
                      <DashboardPaginator
                        filters={{
                          page: pageCriteria.offset,
                        }}
                        goToNextPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset + pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        goToPreviousPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset - pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        isClientSidePagination
                        pageTotal={clientList.length}
                      />
                    </>
                  );
                }}
              </Query>
            </Box>
          );
        }}
      />
    </Route>
  );
};

ClientDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default ClientDashboardPage;
