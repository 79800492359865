import React from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import DropdownMenu from '@shoreag/base/DropdownMenu';
import Table from '@shoreag/base/Table';
import { Query, useMutation } from 'react-apollo';
import AutoSave from '@shoreag/base/AutoSave';
import { Form } from 'react-final-form';
import { TooltipBox } from '@shoreag/base/Tooltip';
import { get, noop } from 'lodash';
import PropTypes from 'prop-types';
import DotsIcon from '../DotsIcon';
import Route from '../Route';
import ToolbarButton from '../ToolbarButton';
import renderDropdownMenuItem from '../../utilities/render-dropdown-menu-item';
import DisplayUserStatus from '../DisplayUserStatus';
import dashboardConstants from '../../utilities/dashboard-constants';
import setDashboardURL from '../../utilities/set-dashboard-url';
import DashboardPaginator from '../DashboardPaginator';
import allClientUserQuery from '../../graphql/queries/all-client-user.gql';
import DisplayUserGroupData from '../DisplayUserGroupData';
import DisplayRolesData from '../DisplayRolesData';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import useSnackbar from '../../utilities/use-snackbar';
import deleteClientUserMutation from '../../graphql/mutations/delete-client-user.gql';
import enableDisableClientUserMutation from '../../graphql/mutations/enable-disable-client-user.gql';
import DisplayTradingPartners from '../DisplayTradingPartners';
import DisplayUserSearchName from '../DisplayUserSearchName';
import UserSearchFilterForm from '../UserSearchFilterForm';

const ClientUserDashboardPage = ({ clientId, location, navigate }) => {
  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'client_users',
  });

  const showFilter = location.pathname.includes('/all-users');
  const { values } = filters;
  const [deleteClientUser] = useMutation(deleteClientUserMutation);
  const [enableDisableClientUser] = useMutation(
    enableDisableClientUserMutation
  );

  const [setSnack] = useSnackbar();
  const [setErrorSnack] = useSnackbar({ error: true });

  return (
    <Route
      header={{
        rightContainer: (
          <Box
            sx={{
              display: 'flex',
              gap: 2,
            }}
          >
            <ToolbarButton
              active
              icon="plus"
              label="User"
              link={`/client/${clientId}/create-user`}
            />
            <ToolbarButton
              icon="plus"
              label="Group"
              link={`/client/${clientId}/create-group`}
            />
          </Box>
        ),
        title: 'User management',
      }}
      isPrivate
    >
      <UserSearchFilterForm
        clientId={clientId}
        filters={filters}
        page={pageCriteria}
        showFilter={showFilter}
        sort={sortCriteria}
      />
      <Form
        initialValues={{
          groupId: values?.groupId || '',
          name: values?.name || '',
          roleId: values?.roleId || '',
          status: values?.status || '',
        }}
        onSubmit={noop}
        render={(formContext) => {
          const { values, handleSubmit } = formContext;

          return (
            <Box
              as="form"
              onSubmit={handleSubmit}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <AutoSave
                save={(all, changed, added, deleted, values) => {
                  return navigate(
                    setDashboardURL({
                      filters,
                      filterValues: values,
                      page: {
                        offset: 0,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  );
                }}
              />

              <Query
                fetchPolicy="network-only"
                notifyOnNetworkStatusChange
                query={allClientUserQuery}
                variables={{
                  searchBody: JSON.stringify({
                    clientId,
                    groupId: values.groupId,
                    limit: pageCriteria.pageSize,
                    name: values.name,
                    offset: pageCriteria.offset,
                    roleId: values.roleId,
                    status: values.status,
                  }),
                }}
              >
                {({ data, loading }) => {
                  const allUser = get(data, 'allClientUser.users', []);
                  const groupName = get(data, 'allClientUser.groupName', '');
                  return (
                    <>
                      {groupName ? (
                        <Box
                          bg="white"
                          fontSize={4}
                          fontWeight={600}
                          p={5}
                          sx={{
                            borderBottom: '1px solid',
                            borderColor: 'gray2',
                          }}
                        >
                          {groupName}
                        </Box>
                      ) : null}
                      <Card m={0} p={0} sx={{ borderRadius: 0 }}>
                        <Table
                          header={[
                            'Name',
                            'Role',
                            'Groups',
                            'Status',
                            'Trading Partners',
                            '',
                          ]}
                          isLoading={loading}
                          rows={allUser?.map((user) => {
                            const dropdownItems = [['edit', 'Edit']];
                            const dropdownActions = [
                              `/client/${clientId}/user/${user.id}/edit`,
                            ];

                            dropdownItems.push(['close', 'Delete']);
                            dropdownActions.push(async () => {
                              try {
                                const {
                                  data: {
                                    deleteClientUser: { email },
                                  },
                                } = await deleteClientUser({
                                  update: (client) => {
                                    client.writeQuery({
                                      data: {
                                        ...allUser,
                                      },
                                      query: allClientUserQuery,
                                    });
                                  },
                                  variables: {
                                    clientId,
                                    email: user.email,
                                    id: user.id,
                                  },
                                });

                                setSnack(
                                  <Box sx={{ textAlign: 'center' }}>
                                    User:
                                    <b> {email}</b>
                                    is deleted{' '}
                                    <Box as="span" sx={{ color: 'error' }}>
                                      successfully.
                                    </Box>
                                  </Box>
                                );
                                navigate(window.location.pathname);
                              } catch (e) {
                                setErrorSnack(
                                  'Deletion failed, try again later'
                                );
                              }
                            });

                            if (user.status === 'active') {
                              dropdownItems.push([
                                'deactivate',
                                'Disable User',
                              ]);
                              dropdownActions.push(async () => {
                                try {
                                  await enableDisableClientUser({
                                    update: (client) => {
                                      client.writeQuery({
                                        data: {
                                          ...allUser,
                                        },
                                        query: allClientUserQuery,
                                      });
                                    },
                                    variables: {
                                      id: user.id,
                                      status: 'inactive',
                                    },
                                  });

                                  setSnack(
                                    <Box sx={{ textAlign: 'center' }}>
                                      User disable{' '}
                                      <Box as="span" sx={{ color: 'error' }}>
                                        successfully.
                                      </Box>
                                    </Box>
                                  );
                                  navigate(window.location.pathname);
                                } catch (e) {
                                  setErrorSnack(
                                    'Disable failed, try again later'
                                  );
                                }
                              });
                            }

                            if (user.status === 'inactive') {
                              dropdownItems.push(['activate', 'Enable User']);
                              dropdownActions.push(async () => {
                                try {
                                  await enableDisableClientUser({
                                    update: (client) => {
                                      client.writeQuery({
                                        data: {
                                          ...allUser,
                                        },
                                        query: allClientUserQuery,
                                      });
                                    },
                                    variables: {
                                      id: user.id,
                                      status: 'active',
                                    },
                                  });

                                  setSnack(
                                    <Box sx={{ textAlign: 'center' }}>
                                      User enable{' '}
                                      <Box as="span" sx={{ color: 'success' }}>
                                        successfully.
                                      </Box>
                                    </Box>
                                  );
                                  navigate(window.location.pathname);
                                } catch (e) {
                                  setErrorSnack(
                                    'Enable failed, try again later'
                                  );
                                }
                              });
                            }

                            const displayName =
                              user.firstName || user.lastName
                                ? `${user.firstName} ${user.lastName}`
                                : user.email || '-NA-';
                            return [
                              <DisplayUserSearchName
                                icon="user"
                                name={displayName}
                              />,
                              user?.roles?.length ? (
                                <DisplayRolesData roles={user?.roles} />
                              ) : (
                                '-'
                              ),
                              user?.groups?.length ? (
                                <DisplayUserGroupData groups={user?.groups} />
                              ) : (
                                '-'
                              ),
                              user?.status ? (
                                <DisplayUserStatus status={user.status} />
                              ) : (
                                '-'
                              ),
                              user?.partners?.length ? (
                                <DisplayTradingPartners
                                  partners={user?.partners}
                                />
                              ) : (
                                '-'
                              ),
                              <Box sx={{ float: 'right' }}>
                                <DropdownMenu
                                  button={<DotsIcon />}
                                  itemActions={dropdownActions}
                                  items={dropdownItems.map(
                                    renderDropdownMenuItem
                                  )}
                                />
                              </Box>,
                            ];
                          })}
                        />
                        <TooltipBox id="tooltip" />
                        <TagTooltipBox />
                      </Card>
                      <DashboardPaginator
                        filters={{
                          page: pageCriteria.offset,
                        }}
                        goToNextPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset + pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        goToPreviousPage={() =>
                          navigate(
                            setDashboardURL({
                              filters,
                              filterValues: filters.values,
                              page: {
                                offset:
                                  pageCriteria.offset - pageCriteria.pageSize,
                                pageSize: pageCriteria.pageSize,
                              },
                              pathname: location.pathname,
                              sort: sortCriteria,
                            }),
                            { replace: true }
                          )
                        }
                        isClientSidePagination
                        pageTotal={allUser.length}
                      />
                    </>
                  );
                }}
              </Query>
            </Box>
          );
        }}
      />
    </Route>
  );
};

ClientUserDashboardPage.propTypes = {
  clientId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default ClientUserDashboardPage;
