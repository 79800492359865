import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';

const ClientDisplayFeatureData = ({ features, featuresLength }) => {
  return (
    <>
      <Box as="h2" color="grays.4" fontSize={3} fontWeight={400} mb={4}>
        Features:
      </Box>
      <Box
        sx={{
          breakInside: 'avoid',
          columnCount: featuresLength > 4 ? 3 : 0,
        }}
      >
        {featuresLength > 0 ? (
          features.map((feature, i) => {
            return (
              <Box key={i} fontSize={2} fontWeight={600} mb={1}>
                {feature.featuresName}
              </Box>
            );
          })
        ) : (
          <Box>-NA-</Box>
        )}
      </Box>
    </>
  );
};

ClientDisplayFeatureData.defaultProps = {
  features: [],
};

ClientDisplayFeatureData.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  featuresLength: PropTypes.number.isRequired,
};

export default ClientDisplayFeatureData;
