import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
// import { composeValidations, email, required } from '@shoreag/validations';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import Dropdown from '@shoreag/base/Dropdown';
import Card from '@shoreag/base/Card';
import Input from '@shoreag/base/Input';
import { FORM_ERROR } from 'final-form';
import { Mutation, useQuery } from 'react-apollo';
import ToggleSwitch from '@shoreag/base/ToggleSwitch';
import { required } from '@shoreag/validations';
import arrayMutators from 'final-form-arrays';
import FormError from '../FormError';
import clientFeatureQuery from '../../graphql/queries/client-feature.gql';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import createClientMutation from '../../graphql/mutations/create-client.gql';
import PillsGroup from '../PillsGroup';
import AddButton from '../AddButton';
import useSnackbar from '../../utilities/use-snackbar';

import AllPartnersDropdown from '../AllPartnersDropdown';
import ClientFeatureList from '../ClientFeatureList';
import AllClientUserDropdown from '../AllClientUserDropdown';

const ClientCreatePage = ({ clientId, navigate }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  if (isAuthenticated && !user.isAdmin) return <NotFoundPage />;

  const { data, loading } = useQuery(clientFeatureQuery, {
    fetchPolicy: 'network-only',
  });
  const [setSnack] = useSnackbar();
  const clientFeature = get(data, 'clientFeature', []);
  if (loading) return <Spinner />;
  return (
    <Route
      header={{
        title: `${clientId ? 'Create' : 'Update'} Client`,
      }}
      isPrivate
    >
      <Mutation mutation={createClientMutation}>
        {(createClient) => (
          <FinalForm
            mutators={arrayMutators}
            onSubmit={async (values) => {
              const updatedFeature = clientFeature
                .filter((feature, index) => values?.features?.[index])
                .map((feature) => feature.id);
              const payload = {
                variables: {
                  capacity: values?.capacity ? values.capacity : 0,
                  clientName: values?.clientName ? values.clientName : '',
                  features: updatedFeature || [],
                  partners: (values?.partners || []).map(
                    (partner) => partner.id
                  ),
                  users: values?.users ? values.users : [],
                },
              };
              try {
                const {
                  data: {
                    createClient: { clientName },
                  },
                } = await createClient(payload);
                if (clientName) {
                  setSnack(
                    <Box sx={{ textAlign: 'center' }}>
                      Client: <b>{clientName}</b> is created
                      <Box as="span" sx={{ color: 'success' }}>
                        successfully.
                      </Box>
                    </Box>
                  );
                  navigate('/client/');
                }
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={(formContext) => {
              const isPartnerAssociated =
                formContext?.values?.tradingPartnersAssociate;
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Box
                    display={{ lg: 'flex' }}
                    justifyContent="center"
                    sx={{ gap: '30px', px: 7 }}
                  >
                    <Box width="100%">
                      <Card>
                        <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                          Details
                        </Box>
                        <Field
                          component={Input}
                          label="Name"
                          name="clientName"
                          validate={required}
                          wrapperSx={{ mb: 5 }}
                        />
                        <Field
                          component={Dropdown}
                          label="Capacity (Max Users)"
                          name="capacity"
                          options={[
                            { label: '10', value: 10 },
                            { label: '20', value: 20 },
                            { label: '30', value: 30 },
                          ]}
                          wrapperSx={{ mb: 5 }}
                        />
                        <Field
                          component={ToggleSwitch}
                          errorSx={{
                            ml: 4,
                            mt: 0,
                          }}
                          label="Associate Trading Partners"
                          labelSx={{
                            ml: 0,
                            mr: 3,
                            order: 'unset',
                          }}
                          name="tradingPartnersAssociate"
                          type="checkbox"
                          wrapperSx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        />
                        {isPartnerAssociated && (
                          <AllPartnersDropdown
                            isMulti
                            isRequired={false}
                            name="partners"
                            showLabel={false}
                            wrapperSx={{
                              mt: 3,
                            }}
                          />
                        )}
                        <PillsGroup
                          data={get(formContext.values, `partners`, []).map(
                            (partner) => ({
                              label: partner.partnerName,
                            })
                          )}
                          onRemove={(parameter) =>
                            formContext.form.change(
                              `partners`,
                              get(formContext.values, `partners`, []).filter(
                                (p) => p.partnerName !== parameter.label
                              )
                            )
                          }
                          pillSx={{
                            '& a': { textDecoration: 'none' },
                            '& div': { bg: 'purple', color: 'white' },
                            '&:hover': { bg: 'purple' },
                            bg: 'purple',
                            borderRadius: '1rem',
                          }}
                          wrapperSx={{
                            pt: 4,
                          }}
                        />
                      </Card>
                      <Card>
                        <Box as="h2" sx={{ fontSize: 4, mb: 2, mt: 0 }}>
                          Invite user(s)
                        </Box>
                        <Box
                          sx={{
                            color: 'grays.4',
                            fontSize: 2,
                            mt: 1,
                          }}
                        >
                          This user(s) is the admin. The admin will have all the
                          permissions of this client.
                        </Box>
                        {/* AllClientUserDropdown */}
                        <AllClientUserDropdown
                          isMulti
                          isRequired={false}
                          name="users"
                          showLabel={false}
                          wrapperSx={{
                            mt: 5,
                          }}
                        />
                        <AddButton
                          label="Add user"
                          labelSx={{
                            color: 'accent',
                          }}
                          sx={{
                            '&:hover': { bg: 'accentLight' },
                            alignItems: 'center',
                            bg: 'accent',
                            display: 'flex',
                            fontSize: 4,
                            height: '16px',
                            justifyContent: 'center',
                            width: '16px',
                          }}
                          wrapperSx={{ mt: 3 }}
                        />
                      </Card>
                    </Box>
                    <Box width="100%">
                      <ClientFeatureList clientFeature={clientFeature} />
                    </Box>
                  </Box>

                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'center',
                      mt: 6,
                      width: 'auto',
                    }}
                  >
                    <Button
                      onClick={() => navigate('/client')}
                      sx={{
                        '&:hover': { bg: 'transparent' },
                        bg: 'transparent',
                        borderColor: 'accent',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        color: 'accent',
                      }}
                    >
                      Cancel
                    </Button>
                    {clientId ? (
                      <Button width="submitButton">Update Client</Button>
                    ) : (
                      <Button
                        submitting={formContext.submitting}
                        type="submit"
                        width="submitButton"
                      >
                        Create Client
                      </Button>
                    )}
                  </Box>
                </form>
              );
            }}
          />
        )}
      </Mutation>
    </Route>
  );
};

ClientCreatePage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

ClientCreatePage.defaultProps = {
  clientId: null,
};

export default ClientCreatePage;
