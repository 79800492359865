import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import DisplayPartnerData from '../DisplayPartnerData';

const DisplayTradingPartners = ({ partners }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {partners?.map((partner, index) => {
        const { id: partnerId } = partner;
        return (
          <Fragment key={partnerId}>
            <DisplayPartnerData partnerId={partnerId} />
            {index + 1 < partners.length ? <>&nbsp; | &nbsp;</> : null}
          </Fragment>
        );
      })}
    </Box>
  );
};

DisplayTradingPartners.propTypes = {
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ).isRequired,
};

export default DisplayTradingPartners;
