import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import { get } from 'lodash';
import { Query } from 'react-apollo';
import clientCheckUserQuery from '../../graphql/queries/client-check-user.gql';
import ClientUserDataList from '../ClientUserDataList';
// import useSnackbar from '../../utilities/use-snackbar';

const CheckUserExists = ({ users, clientId, formContext }) => {
  return (
    <Box>
      {users.map((item) => {
        return (
          <>
            <Query
              fetchPolicy="network-only"
              query={clientCheckUserQuery}
              variables={{
                clientId,
                email: item.email,
              }}
            >
              {({ data }) => {
                const userExists = get(data, 'clientCheckUser.exists', null);
                // if (userExists) {
                //   formContext.form.change(
                //     `users`,
                //     get(formContext.values, `users`, []).filter(
                //       (p) => p.email !== item.email
                //     )
                //   );
                // }

                return (
                  userExists && (
                    <Box color="error" fontSize={2} pt={2}>
                      {item.email} is already assigned to another client
                    </Box>
                  )
                );
              }}
            </Query>
          </>
        );
      })}
      <>
        <Box height="80px" />
        <ClientUserDataList
          data={get(formContext.values, `users`, []).map((user) => ({
            label: user.email,
          }))}
          onRemove={(parameter) =>
            formContext.form.change(
              `users`,
              get(formContext.values, `users`, []).filter(
                (p) => p.email !== parameter.label
              )
            )
          }
        />
      </>
    </Box>
  );
};

CheckUserExists.propTypes = {
  clientId: PropTypes.string,
  formContext: PropTypes.shape({
    form: PropTypes.shape({
      change: PropTypes.func,
    }),
    values: PropTypes.shape({}),
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CheckUserExists.defaultProps = {
  clientId: null,
};

export default CheckUserExists;
