import PropTypes from 'prop-types';
import React from 'react';
import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import { FieldArray } from 'react-final-form-arrays';
import ClientFeatureItem from './ClientFeatureItem';

const ClientFeatureList = ({ clientFeature }) => (
  <Card>
    <Box as="h2" sx={{ fontSize: 4, mb: 5, mt: 0 }}>
      Features
    </Box>
    <FieldArray name="features">
      {() =>
        clientFeature.map((feature, index) => (
          <ClientFeatureItem key={feature.id} feature={feature} index={index} />
        ))
      }
    </FieldArray>
  </Card>
);

ClientFeatureList.propTypes = {
  clientFeature: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ClientFeatureList;
