import PropTypes from 'prop-types';
import AutoSave from '@shoreag/base/AutoSave';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import React from 'react';
import { useApolloClient } from 'react-apollo';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import { Location } from '@reach/router';
import { navigate } from 'gatsby';
import allPartnerDropdownQuery from '../../../graphql/queries/all-partner-dropdown.gql';
import setDashboardURL from '../../../utilities/set-dashboard-url';

const ClientSearchFilterForm = ({ filters, page, sort }) => {
  const client = useApolloClient();
  const { values } = filters;
  return (
    <Box
      sx={{
        height: 'auto',
        maxHeight: '300px',
        overflow: 'visible',
      }}
    >
      <Location>
        {({ location: { pathname } }) => (
          <Form
            initialValues={values}
            onSubmit={noop}
            render={() => (
              <Box
                sx={{
                  alignItems: 'flex-end',
                  borderRadius: 2,
                  display: 'flex',
                  flexWrap: 'wrap',
                  fontSize: 2,
                  justifyContent: 'flex-start',
                  mb: 4,
                  p: 0,
                }}
              >
                <AutoSave
                  save={(all, changed, added, deleted, values) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: values,
                        page: {
                          offset: 0,
                          pageSize: page.pageSize,
                        },
                        pathname,
                        sort,
                      }),
                      { replace: true }
                    );
                  }}
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Field
                      component={Input}
                      name="name"
                      placeholder="Search by name"
                      wrapperSx={{ minWidth: '324px' }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Field
                      component={Dropdown}
                      isClearable
                      loadOptions={async () => {
                        const {
                          data: { allPartner },
                        } = await client.query({
                          query: allPartnerDropdownQuery,
                        });

                        return allPartner.map(({ id, partnerName }) => ({
                          label: partnerName,
                          value: id,
                        }));
                      }}
                      name="partnerId"
                      placeholder="Partner"
                      wrapperSx={{ width: '324px' }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          />
        )}
      </Location>
    </Box>
  );
};

ClientSearchFilterForm.propTypes = {
  filters: PropTypes.shape({
    values: PropTypes.shape({}),
  }).isRequired,
  page: PropTypes.shape({
    offset: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  sort: PropTypes.shape({}).isRequired,
};

export default ClientSearchFilterForm;
