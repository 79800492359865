import Dropdown from '@shoreag/base/Dropdown';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { Field } from 'react-final-form';
import { required } from '@shoreag/validations';
import allClientUserDropdownQuery from '../../graphql/queries/all-client-user.gql';
import MultiSelectDropdown from '../MultiSelectDropdown';

const AllClientUserDropdown = ({
  isRequired,
  clientId,
  name,
  isMulti,
  expectedValue,
  showLabel,
  ...rest
}) => {
  const [externalAsyncOptions, setExternalAsyncOptions] = useState([]);
  return (
    <ApolloConsumer>
      {(client) => (
        <Field
          component={isMulti ? MultiSelectDropdown : Dropdown}
          externalAsyncOptions={externalAsyncOptions}
          label={showLabel ? `All Emails ${isRequired ? '*' : ''}` : ''}
          loadOptions={async () => {
            const {
              data: { allClientUser },
            } = await client.query({
              query: allClientUserDropdownQuery,
              variables: {
                searchBody: JSON.stringify({
                  clientId,
                  optimized: false,
                }),
              },
            });

            return allClientUser.users.map(({ id, email }) => ({
              label: email,
              value: {
                email,
                id,
              },
            }));
          }}
          name={name}
          setExternalAsyncOptions={setExternalAsyncOptions}
          validate={isRequired ? required : null}
          {...rest}
        />
      )}
    </ApolloConsumer>
  );
};

AllClientUserDropdown.propTypes = {
  clientId: PropTypes.string.isRequired,
  expectedValue: PropTypes.string,
  isMulti: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showLabel: PropTypes.bool.isRequired,
};

AllClientUserDropdown.defaultProps = {
  expectedValue: '',
  isMulti: false,
  isRequired: true,
};

export default AllClientUserDropdown;
