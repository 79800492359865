import { stringify } from 'query-string';
import moment from 'moment';

const setDashboardURL = ({ filters, filterValues, page, pathname, sort }) => {
  const values = Object.keys(filterValues).reduce((res, key) => {
    const value = filterValues[key];
    const { type } = filters[key];
    if (value !== null) {
      if (type === 'DatePicker') {
        const isValidDates = value.every((i) => moment(i).isValid());
        if (isValidDates) {
          res[key] = value.map((i) => moment(i).valueOf());
        }
      } else {
        res[key] = value;
      }
    }
    return res;
  }, {});
  const searchParams = stringify({
    ...(values ? { filters: stringify(values) } : {}),
    ...(page ? { page: stringify(page) } : {}),
    ...(sort ? { sort: stringify(sort) } : {}),
  });
  return `${pathname}${searchParams ? '?' : ''}${searchParams}`;
};
export default setDashboardURL;
