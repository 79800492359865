import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
import { composeValidations, email, required } from '@shoreag/validations';
import Checkbox from '@shoreag/base/Checkbox';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import Input from '@shoreag/base/Input';
import Card from '@shoreag/base/Card';
import { Mutation, useQuery } from 'react-apollo';
import updateClientUser from '../../graphql/mutations/update-client-user.gql';
import clientUserQuery from '../../graphql/queries/client-user.gql';
import useSnackbar from '../../utilities/use-snackbar';
import Route from '../Route';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import AllClientGroupDropdown from '../AllClientGroupDropdown';

const ClientUserEditPage = ({ clientId, userId, navigate }) => {
  const { user: authUser } = useContext(AuthContext);
  if (!authUser.isAdmin) return <NotFoundPage />;
  const [setSnack] = useSnackbar();

  const { data, loading } = useQuery(clientUserQuery, {
    fetchPolicy: 'network-only',
    variables: { id: userId || '' },
  });

  const clientUser = get(data, 'clientUser', {});

  const initialValues = {
    ...clientUser,
    groups: get(clientUser, 'groups', []).map((group) => group.id),
  };
  if (loading) return <Spinner />;

  return (
    <Route
      header={{
        icon: 'users',
        title: 'Create User',
        type: 'User',
      }}
      isPrivate
    >
      <Mutation mutation={updateClientUser}>
        {(updateClientUser) => (
          <FinalForm
            initialValues={initialValues}
            mutators={arrayMutators}
            onSubmit={async (values) => {
              const payload = {
                variables: {
                  email: values?.email ? values.email : '',
                  firstName: values?.firstName ? values.firstName : '',
                  groups: (values?.groups || []).map((group) => group.id),
                  id: userId,
                  lastName: values?.lastName ? values.lastName : '',
                },
              };
              try {
                const {
                  data: {
                    updateClientUser: { email },
                  },
                } = await updateClientUser(payload);
                if (email) {
                  setSnack(
                    <Box sx={{ textAlign: 'center' }}>
                      User:
                      <b> {email}</b>
                      is updated{' '}
                      <Box as="span" sx={{ color: 'success' }}>
                        successfully.
                      </Box>
                    </Box>
                  );
                  navigate(`/client/${clientId}`);
                }
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={(formContext) => {
              const isAdmin = formContext?.values?.isAdmin;
              if (isAdmin) {
                formContext.form.change('groupId', '');
              }
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Card maxWidth="maxWidths.largeForm" mb={5} mx="auto" pb={5}>
                    <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                      Details
                    </Box>
                    <Field
                      component={Input}
                      label="Enter Email"
                      name="email"
                      validate={composeValidations(required, email)}
                    />
                    <Field
                      component={Input}
                      label="Enter FirstName"
                      name="firstName"
                      wrapperSx={{
                        mt: 5,
                      }}
                    />
                    <Field
                      component={Input}
                      label="Enter LastName"
                      name="lastName"
                      wrapperSx={{
                        mt: 5,
                      }}
                    />
                    <Field
                      component={Checkbox}
                      label="This user is an admin"
                      name="isAdmin"
                      type="checkbox"
                      wrapperSx={{
                        alignItems: 'center',
                        display: 'flex',
                        mb: 5,
                        mt: 5,
                      }}
                    />
                    <AllClientGroupDropdown
                      clientId={clientId}
                      data-cy="groups"
                      disabled={isAdmin}
                      isMulti
                      isRequired={false}
                      name="groups"
                      showLabel
                      wrapperSx={{
                        mb: 5,
                        pb: 5,
                      }}
                    />
                  </Card>
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'center',
                      mt: 6,
                      width: 'auto',
                    }}
                  >
                    <Button
                      onClick={() => navigate(`/client/${clientId}`)}
                      sx={{
                        '&:hover': { bg: 'transparent' },
                        bg: 'transparent',
                        borderColor: 'accent',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        color: 'accent',
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      submitting={formContext.submitting}
                      type="submit"
                      width="submitButton"
                    >
                      Update User
                    </Button>
                  </Box>
                </form>
              );
            }}
          />
        )}
      </Mutation>
    </Route>
  );
};

ClientUserEditPage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
  userId: PropTypes.string,
};

ClientUserEditPage.defaultProps = {
  clientId: null,
  userId: null,
};

export default ClientUserEditPage;
