import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import { sentenceCase } from 'change-case';
import { ThemeContext } from 'styled-components';
import { STATUS_COLORS } from '../../utilities/constants';

const DisplayUserStatus = ({ size, status }) => {
  const theme = useContext(ThemeContext);
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: 2,
      }}
    >
      <Box as="svg" height={size} width={size}>
        <Box
          as="circle"
          cx={size / 2}
          cy={size / 2}
          fill={theme.colors[STATUS_COLORS[status]]}
          r={size / 2}
        />
      </Box>
      <Box
        sx={{
          color: 'black',
        }}
      >
        {sentenceCase(status)}
      </Box>
    </Box>
  );
};

DisplayUserStatus.propTypes = {
  size: PropTypes.number,
  status: PropTypes.string.isRequired,
};
DisplayUserStatus.defaultProps = {
  size: 8,
};

export default DisplayUserStatus;
