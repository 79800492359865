import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';

const DisplayUserGroupData = ({ groups }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {groups?.map((group, index) => {
        return (
          <Fragment key={group.id}>
            <Box color="primary" fontWeight={500}>
              {group.groupName}
            </Box>
            {index + 1 < groups.length ? <>&nbsp;,&nbsp;</> : null}
          </Fragment>
        );
      })}
    </Box>
  );
};

DisplayUserGroupData.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      groupName: PropTypes.string,
    })
  ).isRequired,
};

export default DisplayUserGroupData;
