import PropTypes from 'prop-types';
import AutoSave from '@shoreag/base/AutoSave';
import Box from '@shoreag/base/Box';
import Dropdown from '@shoreag/base/Dropdown';
import Input from '@shoreag/base/Input';
import React from 'react';
import { useApolloClient } from 'react-apollo';
import noop from 'lodash/noop';
import { Field, Form } from 'react-final-form';
import { Location } from '@reach/router';
import { Link, navigate } from 'gatsby';
import allClientRoleQuery from '../../graphql/queries/all-client-role.gql';
import setDashboardURL from '../../utilities/set-dashboard-url';
import AllClientGroupDropdown from '../AllClientGroupDropdown';

const UserSearchFilterForm = ({
  clientId,
  filters,
  page,
  showFilter,
  sort,
}) => {
  const client = useApolloClient();
  const { values } = filters;
  return (
    <Box
      sx={{
        height: 'auto',
        maxHeight: '300px',
        overflow: 'visible',
      }}
    >
      <Location>
        {({ location: { pathname } }) => (
          <Form
            initialValues={values}
            onSubmit={noop}
            render={() => (
              <Box
                sx={{
                  alignItems: 'flex-end',
                  borderRadius: 2,
                  display: 'flex',
                  flexWrap: 'wrap',
                  fontSize: 2,
                  justifyContent: 'flex-start',
                  mb: 4,
                  p: 0,
                }}
              >
                <AutoSave
                  save={(all, changed, added, deleted, values) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: values,
                        page: {
                          offset: 0,
                          pageSize: page.pageSize,
                        },
                        pathname,
                        sort,
                      }),
                      { replace: true }
                    );
                  }}
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Field
                      component={Input}
                      name="name"
                      placeholder="Search by name"
                      wrapperSx={{ minWidth: '250px' }}
                    />
                    {showFilter && (
                      <AllClientGroupDropdown
                        clientId={clientId}
                        isClearable
                        isRequired={false}
                        name="groupId"
                        wrapperSx={{
                          minWidth: '200px',
                          mt: 0,
                        }}
                      />
                    )}
                    <Link to={`/client/${clientId}/all-users`}>
                      <Box p={4}>All Users</Box>
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                    }}
                  >
                    <Field
                      component={Dropdown}
                      isClearable
                      loadOptions={async () => {
                        const variables = {
                          clientId,
                        };
                        const {
                          data: { allClientRole },
                        } = await client.query({
                          query: allClientRoleQuery,
                          variables,
                        });

                        return allClientRole.map(({ id, roleName }) => ({
                          label: roleName,
                          value: id,
                        }));
                      }}
                      name="roleId"
                      placeholder="Role"
                      wrapperSx={{ width: '168px' }}
                    />
                    {showFilter && (
                      <Field
                        component={Dropdown}
                        isClearable
                        name="status"
                        options={[
                          { label: 'Active', value: 'active' },
                          { label: 'Inactive', value: 'inactive' },
                          { label: 'Invited', value: 'invited' },
                        ]}
                        placeholder="Status"
                        wrapperSx={{ mt: 0, width: '168px' }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          />
        )}
      </Location>
    </Box>
  );
};

UserSearchFilterForm.propTypes = {
  clientId: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    values: PropTypes.shape({}),
  }).isRequired,
  page: PropTypes.shape({
    offset: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  showFilter: PropTypes.bool.isRequired,
  sort: PropTypes.shape({}).isRequired,
};

export default UserSearchFilterForm;
