import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';

const DisplayRolesData = ({ roles }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {roles?.map((role, index) => {
        return (
          <Fragment key={role.id}>
            <Box color="grays.4" fontWeight={600}>
              {role.roleName}
            </Box>
            {index + 1 < roles.length ? <>&nbsp;,&nbsp;</> : null}
          </Fragment>
        );
      })}
    </Box>
  );
};

DisplayRolesData.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleName: PropTypes.string,
    })
  ).isRequired,
};

export default DisplayRolesData;
