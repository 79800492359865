import React from 'react';
import PropTypes from 'prop-types';
import Box from '@shoreag/base/Box';
import Icon from '../Icon';

const DisplayUserSearchName = ({ icon, name = '' }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
      }}
    >
      {icon && (
        <Icon
          svg={icon}
          sx={{
            color: 'grays.3',
            height: '1rem',
            width: '1rem',
          }}
        />
      )}
      {name && (
        <Box
          sx={{
            color: 'black',
            fontSize: 2,
          }}
        >
          {name}
        </Box>
      )}
    </Box>
  );
};

DisplayUserSearchName.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
};
DisplayUserSearchName.defaultProps = {
  icon: null,
  name: null,
};

export default DisplayUserSearchName;
