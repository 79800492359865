import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import ClientDashboardPage from '../../components/ClientDashboardPage';
import ClientCreatePage from '../../components/ClientCreatePage';
import ClientUserDashboardPage from '../../components/ClientUserDashboardPage';
import ClientUserCreatePage from '../../components/ClientUserCreatePage';
import ClientGroupCreatePage from '../../components/ClientGroupCreatePage';
import ClientDirectoryDashboardPage from '../../components/ClientDirectoryDashboardPage';
import ClientGroupEditPage from '../../components/ClientGroupEditPage';
import ClientUserEditPage from '../../components/ClientUserEditPage';

const ClientRouter = (props) => (
  <Router>
    <ClientGroupEditPage
      {...props}
      path="/client/:clientId/group/:groupId/edit"
    />
    <ClientGroupCreatePage {...props} path="/client/:clientId/create-group" />

    <ClientUserEditPage {...props} path="/client/:clientId/user/:userId/edit" />
    <ClientUserCreatePage {...props} path="/client/:clientId/create-user" />

    <ClientUserDashboardPage {...props} path="/client/:clientId/all-users" />
    <ClientDirectoryDashboardPage {...props} path="/client/:clientId" />

    <ClientCreatePage {...props} path="/client/create" />
    <ClientDashboardPage {...props} path="/client/" />
    <NotFoundPage default />
  </Router>
);

export default ClientRouter;
