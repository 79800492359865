import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import Spinner from '@shoreag/base/Spinner';
import get from 'lodash/get';
import { AuthContext } from '@shoreag/auth';
import { Field, Form as FinalForm } from 'react-final-form';
import { composeValidations, email, required } from '@shoreag/validations';
import Checkbox from '@shoreag/base/Checkbox';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import Input from '@shoreag/base/Input';
import Card from '@shoreag/base/Card';
import { Mutation, Query } from 'react-apollo';
import createClientUser from '../../graphql/mutations/create-client-user.gql';
import clientGroupDetailsQuery from '../../graphql/queries/client-group-details.gql';
import useSnackbar from '../../utilities/use-snackbar';
import Route from '../Route';
import FormError from '../FormError';
import NotFoundPage from '../../routes/default/404';
import AllClientGroupDropdown from '../AllClientGroupDropdown';
import DisplayTradingPartners from '../DisplayTradingPartners';

const ClientUserCreatePage = ({ clientId, navigate }) => {
  const { user: authUser } = useContext(AuthContext);
  if (!authUser.isAdmin) return <NotFoundPage />;
  const [setSnack] = useSnackbar();

  return (
    <Route
      header={{
        icon: 'users',
        title: 'Create User',
        type: 'User',
      }}
      isPrivate
    >
      <Mutation mutation={createClientUser}>
        {(createClientUser) => (
          <FinalForm
            initialValues={{
              email: '',
              groupId: '',
            }}
            mutators={arrayMutators}
            onSubmit={async (values) => {
              const payload = {
                variables: {
                  clientId,
                  email: values?.email ? values.email : '',
                  groupId: values?.groupId ? values.groupId : '',
                },
              };
              try {
                const {
                  data: {
                    createClientUser: { email },
                  },
                } = await createClientUser(payload);
                if (email) {
                  setSnack(
                    <Box sx={{ textAlign: 'center' }}>
                      User:
                      <b> {email}</b>
                      is created&nbsp;
                      <Box as="span" sx={{ color: 'success' }}>
                        successfully.
                      </Box>
                    </Box>
                  );
                  navigate(`/client/${clientId}`);
                }
              } catch (e) {
                return {
                  [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
                };
              }
            }}
            render={(formContext) => {
              const isAdmin = formContext?.values?.isAdmin;
              const groupId = formContext?.values?.groupId;
              if (isAdmin) {
                formContext.form.change('groupId', '');
              }
              return (
                <form onSubmit={formContext.handleSubmit}>
                  <Card maxWidth="maxWidths.largeForm" mx="auto">
                    <Box as="h2" sx={{ fontSize: 5, mb: 6, mt: 0 }}>
                      Details
                    </Box>
                    <Field
                      component={Input}
                      label="Invite User (Email-ID)"
                      name="email"
                      validate={composeValidations(required, email)}
                    />
                    <Field
                      component={Checkbox}
                      label="This user is an admin"
                      name="isAdmin"
                      type="checkbox"
                      wrapperSx={{
                        alignItems: 'center',
                        display: 'flex',
                        mb: 5,
                        mt: 5,
                      }}
                    />
                    <AllClientGroupDropdown
                      clientId={clientId}
                      disabled={isAdmin}
                      isClearable={false}
                      isRequired={false}
                      name="groupId"
                      showLabel
                    />
                    <Box
                      sx={{
                        color: isAdmin ? 'grays.2' : 'accentSecondary',
                        fontSize: 2,
                        mt: 1,
                      }}
                    >
                      The user you are creating will be a part of this group.
                    </Box>
                    {!isAdmin && (
                      <Query
                        fetchPolicy="network-only"
                        query={clientGroupDetailsQuery}
                        variables={{ groupId: groupId || '' }}
                      >
                        {({ data, loading }) => {
                          const clientGroupDetails = get(
                            data,
                            'clientGroupDetails',
                            {}
                          );

                          let content = null;

                          if (loading && groupId) {
                            content = <Spinner />;
                          }
                          if (!loading && data) {
                            content = (
                              <>
                                <Box
                                  sx={{
                                    borderBottomStyle: 'solid',
                                    borderColor: 'grays.2',
                                    borderWidth: '1px',
                                    color: 'grays.4',
                                    fontSize: 3,
                                    mb: 1,
                                    mt: 6,
                                    pb: 2,
                                  }}
                                >
                                  {clientGroupDetails.groupName} details
                                </Box>
                                <Box
                                  alignItems="center"
                                  display="flex"
                                  mb="4"
                                  mt="4"
                                >
                                  <Box
                                    sx={{
                                      color: 'grays.4',
                                      fontSize: 3,
                                      width: '160px',
                                    }}
                                  >
                                    Role:
                                  </Box>
                                  <Box>{clientGroupDetails.role}</Box>
                                </Box>
                                <Box alignItems="center" display="flex" my="3">
                                  <Box
                                    sx={{
                                      color: 'grays.4',
                                      fontSize: 3,
                                      width: '160px',
                                    }}
                                  >
                                    Trading partner(s):
                                  </Box>
                                  {clientGroupDetails.partners ? (
                                    <DisplayTradingPartners
                                      partners={clientGroupDetails.partners}
                                    />
                                  ) : (
                                    '-NA-'
                                  )}
                                </Box>
                              </>
                            );
                          }
                          return (
                            <Box mt="6" pt="6">
                              {content}
                            </Box>
                          );
                        }}
                      </Query>
                    )}
                  </Card>
                  <FormError>{formContext.submitError}</FormError>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 2,
                      justifyContent: 'center',
                      mt: 6,
                      width: 'auto',
                    }}
                  >
                    <Button
                      onClick={() => navigate(`/client/${clientId}`)}
                      sx={{
                        '&:hover': { bg: 'transparent' },
                        bg: 'transparent',
                        borderColor: 'accent',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        color: 'accent',
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      submitting={formContext.submitting}
                      type="submit"
                      width="submitButton"
                    >
                      Create User
                    </Button>
                  </Box>
                </form>
              );
            }}
          />
        )}
      </Mutation>
    </Route>
  );
};

ClientUserCreatePage.propTypes = {
  clientId: PropTypes.string,
  navigate: PropTypes.func.isRequired,
};

ClientUserCreatePage.defaultProps = {
  clientId: null,
};

export default ClientUserCreatePage;
