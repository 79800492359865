import Box from '@shoreag/base/Box';
import React from 'react';
import PropTypes from 'prop-types';

const ClientUserDataList = ({
  wrapperSx,
  pillSx,
  data,
  onRemove,
  viewOnly,
}) => {
  return (
    <Box
      height="250px"
      mt={6}
      overflowY="auto"
      sx={{
        ...wrapperSx,
      }}
    >
      {data.map((item, index) => {
        const { label } = item;
        return (
          <Box
            key={`${label}-${index}`}
            sx={{
              borderBottom: '1px solid',
              borderColor: 'grays.2',
              display: 'flex',
              justifyContent: 'space-between',
              py: 4,
              ...pillSx,
            }}
          >
            <Box>{label}</Box>
            {!viewOnly ? (
              <Box
                onClick={() => onRemove(item)}
                sx={{
                  color: 'accent',
                  cursor: 'pointer',
                  fontSize: 2,
                }}
              >
                Remove
              </Box>
            ) : null}
          </Box>
        );
      })}
    </Box>
  );
};

ClientUserDataList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  onRemove: PropTypes.func,
  pillSx: PropTypes.shape({}),
  viewOnly: PropTypes.bool,
  wrapperSx: PropTypes.shape({}),
};

ClientUserDataList.defaultProps = {
  onRemove: () => null,
  pillSx: {},
  viewOnly: false,
  wrapperSx: {},
};

export default ClientUserDataList;
